// 申遗承诺
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "名称",
                    prop: "MC",
                    minWidth: ""
                },
                {
                    label: "类别",
                    prop: "LB_DESC",
                    minWidth: ""
                },
                {
                    label: "公布时间",
                    prop: "GBSJ",
                    minWidth: ""
                },
                {
                    label: "生效或废止状态",
                    prop: "ZT_DESC",
                    minWidth: ""
                },
                {
                    label: "文件内容",
                    prop: "LJ",
                    minWidth: "",
                    showFileBtn: true,
                },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    showMethod: 'showSeeBtn',
                    btnType: 'icon',
                    operateType: 'columnSee',
                    title: '查看',
                    icon: 'el-icon-view',
                    backColor: 'transparent',
                    color: '#20a0ff'
                },
                ]
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            // {
            //     isShow: true,
            //     btnType: 'button',
            //     operateType: 'buttonNew',
            //     showMethod: 'showNewBtn',
            //     name: '新建',
            //     round: true,
            //     backColor: '#28ccd9',
            // },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;